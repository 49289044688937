/*
 * 업무구분: 육성코치활동입력
 * 화 면 명: MSPAP050M
 * 화면설명: 육성코치활동입력
 * 작 성 일: 2023.02.08
 * 작 성 자: 최준석
 */
<template>
  <ur-page-container ref="page" class="msp" :show-title="false" title="육성코치 활동입력" type="subpage" action-type="none" :topButton="true">
    <ur-box-container alignV="start"  direction="column" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview" :zIndex="5">
          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_goMain" />
              <div slot="nav" class="icon-wrapper" @click="fn_goMain">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '육성코치 활동입력' : '육성코치 활동입력'}}</div>
            </mo-top-bar>

            <ur-box-container direction="row" class="msp-step-type2">
              <ur-box-container alignV="start" direction="column" class="ns-input-box pt30">
                <msp-bottom-select v-if="auth==='3' || auth==='4'" :items="sucoaList" v-model="pSucoaNo" @input="fn_selectSucoa" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline placeholder="전체" bottom-title="육성코치" class="mb20" closeBtn scrolling/>
                <ur-box-container alignH="start" direction="row" class="row-box btn-question-wrap">
                  <span class="label-title w100px">활동월</span>
                  <mo-month-picker v-model="targetMonth" placeholder="지급월" :minDate="disabledMonthDate.min" :maxDate='disabledMonthDate.max' @confirmed="fn_btn_S_OnClick" class="ns-month-picker right full" />
                </ur-box-container>
              </ur-box-container>  
              <ur-box-container direction="column" align-v="start" class="ns-info-area">
                <ur-box-container alignV="start" direction="column" class="info-box pb20">
                  <ur-box-container alignV="start" direction="column" >
                    <ul class="terms-list-area--gray mt15">
                      <li>
                        1일3시간 or 2방 이상 시 인정, 주말/공휴일 활동 입력 불가, 신인/지점장 서명 미완료 시 적용불가
                      </li>
                    </ul>
                  </ur-box-container>
                </ur-box-container>
              </ur-box-container>            
            </ur-box-container>
          </template>

          <template #collapsing>
            <span class="text_hidden">collapsing</span>
          </template>


          <!-- stickey 영역 -->
          <template #sticky>
            <!-- 총건수  -->
            <ur-box-container alignV="start" direction="column" class="ns-check-sum">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">총 <strong class="crTy-blue3">{{ICSucoaActPnstaVOList.length}}</strong> 건</span>
                    <!-- {{auth}} -->
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- 총건수  //-->
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area">      
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list type-2" v-if="ICSucoaActPnstaVOList.length > 0">
                <mo-list :list-data="ICSucoaActPnstaVOList">
                  <template #list-item="{item}">
                    <mo-list-item>
                      <div class="list-item__contents mt5">
                        <div class="list-item__contents__title"> 
                          <span class="name txtSkip linkTy" v-if="auth==='1' || auth==='3' || auth==='4'" @click="fn_Open511Pop(item.sucoaNm, item.sucoaNo)">{{item.sucoaNm}}({{item.sucoaNo}})</span>
                          <span class="name txtSkip linkTy" v-else @click="fn_Open511Pop(item.nwmanCnsltNm, item.nwmanCnsltNo)">{{item.nwmanCnsltNm}}({{item.nwmanCnsltNo}})</span>
                          <mo-badge class="badge-sample-badge sm" v-if="!$bizUtil.isEmpty(item.actYmd)" :class="(item.actTm >= 3 || item.vstCnt >= 2)  ? 'lightblue' : 'lightred'" shape="status">{{(item.actTm >= 3 || item.vstCnt >= 2) ? '적용' : '미적용'}}</mo-badge> 
                        </div>
                        <div class="list-item__contents__info" v-if="auth==='3' || auth==='4' ">
                          <span class="crTy-bk7 fs14rem mr10">신인 </span><span class="crTy-bk1" @click="fn_Open511Pop(item.nwmanCnsltNm, item.nwmanCnsltNo)">{{item.nwmanCnsltNm}}({{item.nwmanCnsltNo}})</span>
                        </div>
                        <div class="list-item__contents__info" v-if="!$bizUtil.isEmpty(item.actYmd)">
                          <span class="crTy-bk7 fs14rem mr10">동행일자 </span><span class="crTy-bk1">{{$bizUtil.dateDashFormat(item.actYmd)}}</span>
                        </div>
                        <div class="list-item__contents__info" v-if="!$bizUtil.isEmpty(item.actYmd)">
                          <span class="crTy-bk7 fs14rem mr10">시간 </span><span class="crTy-bk1">{{item.actTm === '0' || item.actTm === ''? '': item.actTm + '시간'}}</span><em>|</em>
                          <span class="crTy-bk7 fs14rem mr10">동행횟수 </span><span class="crTy-bk1">{{item.vstCnt === '0' || item.vstCnt === ''? '': item.vstCnt + '번'}}</span> 
                        </div>
                        <div class="list-item__contents__info" v-if="!$bizUtil.isEmpty(item.actYmd)">
                          <span class="crTy-bk7 fs14rem mr10">신인 </span>
                          <template v-if="item.signYn === '1' && saveYn === 1">
                            <span class="crTy-bk1">서명완료</span>
                          </template>
                          <template v-else>
                            <span class="crTy-bk1" v-if="item.signYn === '1'">서명완료</span>
                            <span class="crTy-orange2" v-else>{{$bizUtil.isEmpty(item.actYmd) ? '-' : '서명전'}}</span>
                          </template>
                          <em>|</em>
                          <span class="crTy-bk7 fs14rem mr10">지점장 </span>
                          <template v-if="item.mngrSignYn === '1' && saveYn === 1 && item.signYn === '1'">
                            <span class="crTy-bk1">서명완료</span>
                          </template>
                          <template v-else>
                            <span class="crTy-bk1" v-if="item.mngrSignYn === '1'">서명완료</span>
                            <span class="crTy-orange2" v-else>{{$bizUtil.isEmpty(item.actYmd) ? '-' : '서명전'}}</span>
                          </template>
                        </div>
                        <div class="ns-btn-relative-area mt20">
                          <div class="ns-btn-relative-s">
                            <div class="relative-div mb5">
                              <template v-if="auth==='1'">
                                <template v-if="item.signYn === '0' && saveYn === 1 && item.mngrSignYn !== '1'">
                                  <mo-button class="ns-btn-round white ml0 mr0" @click="fn_ConfirmSign(item)">서명</mo-button>
                                </template>
                                <template v-else-if="item.signYn === '1' && saveYn === 1 && (item.mngrSignYn === '0' || item.mngrSignYn === '')">
                                  <mo-button class="ns-btn-round white ml0 mr0" @click='fn_ConfirmCancelSign(item, 1)'>서명취소</mo-button>
                                </template>
                                <template v-else>
                                  <mo-button class="ns-btn-round white ml0 mr0" disabled>서명취소</mo-button>
                                </template>
                              </template>
                              <template v-if="auth==='2' && item.signYn !== '1' && saveYn === 1">
                                <mo-button class="ns-btn-round white ml0 mr0" @click="fn_openBsActInpt(item)">활동입력</mo-button>
                              </template>
                              <template v-if="auth==='3' || auth==='4'">
                                <template v-if="(item.mngrSignYn === '0' || item.mngrSignYn === '') && saveYn === 1 && item.signYn === '1'">
                                  <mo-button class="ns-btn-round white ml0 mr0" @click="fn_ConfirmSign(item)">서명</mo-button>
                                </template>
                                <template v-else-if="item.mngrSignYn === '1' && saveYn === 1 && item.signYn === '1'">
                                  <mo-button class="ns-btn-round white ml0 mr0" @click="fn_ConfirmCancelSign(item, 2)">서명취소</mo-button>
                                </template>
                                <template v-else>
                                  <mo-button class="ns-btn-round white ml0 mr0" disabled>{{item.mngrSignYn === '1' ? '서명취소' : '서명'}}</mo-button>
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mo-list-item>                    
                  </template>
                </mo-list>
              </ur-box-container>

              <!-- no-data 조건설정 및 데이타 없음 --> 
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="no-data" v-else>
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info small-space">
                      <span class="ns-ftcr-gray"><p class="mt10">활동 내역이 없어요.</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
              <!-- no-data end -->

            </ur-box-container>

          </template>
          <!-- scroll 영역 //-->

        </mo-collapsing-header-view>
       </div>
    </ur-box-container>

    <mo-bottom-sheet ref="bsActInpt"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        활동입력

        <div class="ns-btn-close" @click="fn_bsActInptClose" name="닫기"></div>
      </template>
       
      <ur-box-container direction="column" alignV="start">   
        <ur-box-container alignV="start" direction="column" class="ns-input-box">
          <ur-box-container alignH="start" direction="row" class="row-box btn-question-wrap">
            <span class="label-title min80">신인</span>
            <mo-text-field  class="form-input-name full" v-model="nwmanCnsltNm" underline placeholder="" readonly />
          </ur-box-container>
          <ur-box-container alignH="start" direction="row" class="row-box btn-question-wrap mt10">
            <span class="label-title min80">동행일자</span>
            <mo-date-picker placeholder="동행일자" class="full" v-model="actYmd" :min-date="disabledDate.min" :max-date="disabledDate.max" />
          </ur-box-container>
        </ur-box-container>     
        <ur-box-container direction="row" class="fexTy3 pl24 pr24 mt10">
          <span class="mr35">시간</span>
          <mo-stepper :min="1" :max="100" v-model="actTm"/>
        </ur-box-container>
        <ur-box-container direction="row" class="fexTy3 pl24 pr24 mt10 mb30">
          <span class="mr35">동행횟수</span>
          <mo-stepper :min="1" :max="100" v-model="vstCnt"/>
        </ur-box-container>
      </ur-box-container>
      
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_bsActInptClose">취소</mo-button>
            <mo-button componentid="mo_button_005" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Validation">저장</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>

<script>


/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
// import DateUtil from '@/ui/sp/common/exDateUtils'
import moment from 'moment'
import MSPAP511P from '@/ui/ap/MSPAP511P'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPAP050M',
  // 현재 화면 ID
  screenId: 'MSPAP050M',
  // 컴포넌트 선언
  components: {
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      lv_HeaderviewScrollCompID: '',
      ICSucoaActPnstaVOList: [],
      sucoaList: [],
      pSucoaNo: '',
      saveYn: 1,
      // sucoaVal: {}, // 선택된 세대원1
      cnsltInfo: this.getStore('userInfo').getters.getUserInfo,
      auth: '', // 1:신인 2:육성코치 3:지점장
      // nwmanCnsltNo: '',
      inqrScVal: '1',
      reqData: {
        iCSucoaActPnstaVO: [
        ]
      },
      salesDds: 0,
      DEBUG: true,
      isTSSAP121P: false, // 활동추가 팝업창 호출 변수
      confirmSign: false, // 신인서명 호출 변수
      confirmMngrSign: false, // 지점장서명 호출 변수
      confirmCancelSign: false, // 서명취소 호출변수
      targetMonth: [String(moment(new Date()).format('YYYY')), String(moment(new Date()).format('MM'))],

      nwmanCnsltNm: '',
      actYmd: '',
      actTm: '',
      vstCnt: '',
      holidays: [],
      lv_actObj: {},
      toastText: ''
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    disabledMonthDate () {
      return {
        min: this.$bizUtil.dateDashFormat(moment(new Date(moment().add(-5, 'months'))).format('YYYYMMDD')),
        max: this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMMDD'))
      }
    },
    disabledDate () {
      return {
        min: this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMM') + '01'),
        max: this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMMDD'))
      }
    }
  },
  
  /** watch 정의 영역 */
  watch: {   
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    this.fn_Init()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*******************************************************************************
    * Function명: fn_goMain
    * 설명: 메인으로 이동
    *******************************************************************************/
    fn_goMain() {
      if (this.$route.query?._isMenuItem) {
        this.$router.push({name: 'MSPBC002M'})
      }else{
        this.$router.go(-1)
      }
    },
    fn_Init: function () {
      this.gfn_orgLvlSlct()
      this.fn_SelListYmdInfo()
    },
    /**
     * 조직정보 설정
     * interface : N/A
     * transaction ID: N/A
     * param :  N/A
     * return :
     * author : kwanghee2018.choi
     * issue : A
    **/
    gfn_orgLvlSlct: function () {
      var lv_Vm = this

      // 컨설턴트 중 'ST','IN','IE','FCSM', "IG", "IF" 직급 제외
      // 20140115  'ST','IN','IE','FCSM', "IG", "IF" 직급 제외 에서 IK,IX로 변경
      // 육성코치 자격있는 사람은 auth = "2"  화면 오픈시 MAIN("S1")에서 육성코치 자격 조회
      // 20200107 지점장 권한 추가 (A7D, A7E, A7K)
      if ( lv_Vm.cnsltInfo.userScCd === '11' && (lv_Vm.cnsltInfo.dutyCd === 'IK' || lv_Vm.cnsltInfo.dutyCd === 'IX' || lv_Vm.cnsltInfo.dutyCd === 'TL' ||
       lv_Vm.cnsltInfo.dutyCd === 'GJ' || lv_Vm.cnsltInfo.dutyCd === 'GK' || lv_Vm.cnsltInfo.dutyCd === 'GT' || lv_Vm.cnsltInfo.dutyCd === 'GH')
      ) { // 신인컨설트일때
        lv_Vm.auth = '1'
      } else if (lv_Vm.cnsltInfo.dutyCd === 'A7D' || lv_Vm.cnsltInfo.dutyCd === 'A7E' || lv_Vm.cnsltInfo.dutyCd === 'A7K') { // 지점장
        lv_Vm.auth = '4'
      } else { // 컨설턴트나 육성코치
        lv_Vm.auth = '3'
      }

      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "",
          content: '[육성코치/신인] 동행 허위 입력, 타인 대리 승인等 <br>부실 확인時 향후 수수료를 환수할 수 있습니다.<br><br>[지점장]  매칭 신인의 實활동 여부 및 동행일자, 시간等 <br>확인 바랍니다. (부실 확인時 관리자 문책 조치可)',
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_AlertPop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
      lv_Vm.fn_SelListSucoaNo()
    },
    /******************************************************************************
    * Function명 : fn_SelListSucoaNo
    * 설명       : 육성코치 조회 I/F 호출
    ******************************************************************************/
    fn_SelListSucoaNo: function () {
      this.isLoading = true
      let lv_Vm = this
      let pParams = {}
      let trnstId = 'txTSSAP12S2'
      lv_Vm.sucoaList.splice(0, lv_Vm.sucoaList.length)
      
      pParams =
                { inqrYm: lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0'),
                  inqrScVal: lv_Vm.inqrScVal // 조회구분값
                }

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null) {
            if (lv_Vm.inqrScVal === '1') {
              lv_Vm.salesDds = Number(response.body.salesDds)
            }

            if (response.body.sucoaNo !== '') { // 육성코치
              lv_Vm.pSucoaNo = response.body.sucoaNo
              lv_Vm.auth = '2'
            } else {
              if (response.body.iCSucoaInfoVO !== null && response.body.iCSucoaInfoVO.length > 0) {
                let lv_SucoaList = response.body.iCSucoaInfoVO

                lv_Vm.sucoaList.push({key: '%', label: '전체'})
                lv_SucoaList.forEach(
                  (item) => {
                    let lv_Item = {}
                    lv_Item.key = item.sucoaNo
                    lv_Item.label = item.sucoaNm
                    lv_Vm.sucoaList.push(lv_Item)
                  }
                )
                lv_Vm.pSucoaNo = '%'
              }
            }
            lv_Vm.inqrScVal = '0'

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.fn_btn_S_OnClick()
        })
        .catch(function (error) {
          window.vue.error(error)
        })

        console.log('lv_Vm.auth >>> ', lv_Vm.auth)
    },
    /******************************************************************************
    * Function명 : fn_SelListYmdInfo
    * 설명       : 법정공휴일 조회 I/F 호출
    ******************************************************************************/
    fn_SelListYmdInfo() {
      let lv_Vm = this

      let pParams = {}
      let trnstId = 'txTSSAP03S2'
      
      pParams.inqrStrYmd = this.$bizUtil.replaceAll(lv_Vm.disabledDate.min, '-', ''), // 시작일자
      pParams.inqrEndYmd = this.$bizUtil.replaceAll(lv_Vm.disabledDate.max, '-', '')// 종료일자
      
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null && response.body.iCYmdMngVO !== null && response.body.iCYmdMngVO.length > 0) {
            response.body.iCYmdMngVO.forEach((item) => {
              if(item.holyCd === '1'){
                lv_Vm.holidays.push(item.slrcYmYmd)
              }
            })
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /**
     * 육성코치활동입력조회
     * interface : IF-AP-0002
     * transaction ID: txTSSAP12S1
     * param : inqrYm, sucoaNo
     * return :  N/A
     * author : kwanghee2018.choi
     * issue  : A
    **/
    fn_btn_S_OnClick: function () {
      var lv_Vm = this
      var nwmanCnsltNo = ''
      var params = {}
      if (lv_Vm.auth === '1') {
        nwmanCnsltNo = lv_Vm.cnsltInfo.userId// 신인컨설턴트번호
        lv_Vm.pSucoaNo = '%'
      } else {
        nwmanCnsltNo = '%'
      }
      params.inqrYm = lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0')
      params.sucoaNo = lv_Vm.pSucoaNo
      params.nwmanCnsltNo = nwmanCnsltNo

      let trnstId = 'txTSSAP12S1'
      let today = String(moment(new Date()).format('YYYYMM'))

      if (lv_Vm.salesDds < 3) {
        if(params.inqrYm === today){
          lv_Vm.getStore('confirm').dispatch('ADD', '당월 활동 입력은 3영업일부터 가능합니다.')// 당월 활동 입력은 3영업일부터 가능합니다.')EAPC006
        }
        lv_Vm.saveYn = 0
      } else if (params.inqrYm !== today) { // 당월 입력이 아닌 경우 저장 버튼 비활성화 처리
        lv_Vm.saveYn = 0
      } else {
        lv_Vm.saveYn = 1
      }
      
      lv_Vm.ICSucoaActPnstaVOList.splice(0, lv_Vm.ICSucoaActPnstaVOList.length)
      this.post(lv_Vm, params, trnstId, 'S')
        .then(function (response) {
          
          // 서버 데이터 가져오기
          if (response.body != null && response.body.iCSucoaActPnstaVO != null) {
            // 서비스 통신 조회키
            // lv_Vm.$data.stndKeyList[0] = response.trnstComm.stndKeyList[0]
            // 서버 데이터 가져오기
            lv_Vm.ICSucoaActPnstaVOList = response.body.iCSucoaActPnstaVO

            if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.toastText)){
              lv_Vm.$addSnackbar(lv_Vm.toastText)
              lv_Vm.toastText = ''
            }
          }
        })
    },
    fn_openBsActInpt(item) {
      this.lv_actObj = item
      this.nwmanCnsltNm = item.nwmanCnsltNm
      if(this.$bizUtil.isEmpty(item.actYmd)){
        this.actYmd = moment(new Date()).format('YYYY-MM-DD')
      }else{
        this.actYmd = this.$bizUtil.dateDashFormat(item.actYmd)
      }
      this.actTm = item.actTm
      this.vstCnt = item.vstCnt
      this.$refs.bsActInpt.open()
    },
    fn_bsActInptClose() {
      this.$refs.bsActInpt.close()
    },
    fn_selectSucoa(val) {
      this.fn_btn_S_OnClick()
    },
    fn_Validation () {
      let lv_RetVal = this.fn_IsValid()
      
      if (lv_RetVal) {
        if (this.auth === '2') {
          if (this.actTm >= 3 || this.vstCnt >= 2) {
            this.fn_ServiceSaveData('pop', 'Y')
          } else {
            let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
              properties: {
                //single: true,
                title: "",
                content: '활동 3시간 이상이거나, 동행횟수 2회<br> 이상인 경우에만 활동 내역이 적용됩니다.<br> 저장하시겠습니까?',
                title_pos_btn: "저장",
                title_neg_btn: "취소"
              },
              listeners: {
                onPopupConfirm: () => {
                  this.$bottomModal.close(lv_AlertPop)
                  this.fn_ServiceSaveData('pop', '')
                },
                onPopupClose: () => {
                  this.$bottomModal.close(lv_AlertPop)
                }
              }
            })
          }
        }
      }
    },
    /******************************************************************************
      * Function명 : fn_IsValid
      * 설명       : 활동추가 유효성 체크
    ******************************************************************************/
    fn_IsValid () {
      let flag = true
      let date = new Date(this.actYmd)

      if(date.getDay() == 6 || date.getDay() == 0){
        flag = false
      }else{
        console.log('this.holidays >>> ', this.holidays)
        this.holidays.forEach((holiday) => {
          console.log(holiday, this.$bizUtil.replaceAll(this.actYmd, '-', ''))
          if(holiday == this.$bizUtil.replaceAll(this.actYmd, '-', '')){
            flag = false
          }
        })
      }

      if(!flag){
        this.getStore('confirm').dispatch('ADD', '휴일은 선택이 불가능합니다.')
      }
      
      return flag
    },
    /******************************************************************************
      * Function명 : fn_ServiceSaveData
      * 설명       : 서비스 호출(활동추가 및 저장)
    ******************************************************************************/
    fn_ServiceSaveData (dvcd, aplcYn) {
      const trnstId = 'txTSSAP12P1'// 내그룹 저장
      const auth = 'U'

      var lv_Vm = this
      if(dvcd == 'pop'){
        lv_Vm.lv_actObj.actYmd = lv_Vm.actYmd.substring(0, 4) + lv_Vm.actYmd.substring(5, 7) + lv_Vm.actYmd.substring(8, 10)
        lv_Vm.lv_actObj.vstCnt = lv_Vm.vstCnt
        lv_Vm.lv_actObj.actTm = lv_Vm.actTm
        lv_Vm.lv_actObj.signYn = '0'
        lv_Vm.lv_actObj.aplcYn = aplcYn
      }
      
      lv_Vm.lv_actObj.inqrYm = lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0')

      if (lv_Vm.auth === '1') {
        lv_Vm.lv_actObj.prcsfCd = '2'
      }else if (lv_Vm.auth === '2') {
        lv_Vm.lv_actObj.prcsfCd = '1'
      }else{
        lv_Vm.lv_actObj.prcsfCd = ''
      }

      let pParams = {}
      pParams.iCSucoaActPnstaVO = []
      pParams.iCSucoaActPnstaVO.push(lv_Vm.lv_actObj)
      
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          console.log('dvcd, response >>> ', dvcd, response, lv_Vm.auth)
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            if (response.body.holyYn === 'Y') {
              lv_Vm.$addSnackbar({text: '휴일은 선택 불가능 합니다.', error: true})
            }
            else if ((response.body.dupYn === '1' || response.body.dupYn === '2' || response.body.dupYn === '3') && lv_Vm.auth == '2') {
              lv_Vm.$addSnackbar({text: '해당일에 활동 내역이 존재합니다.\n신인과 육성코치 間 1:1 매칭이 원칙입니다.', error: true})
            }
            else if (response.body.gtghYn === 'Y' && lv_Vm.auth == '2') {
              lv_Vm.$addSnackbar({text: '위촉일 이전 활동은 입력할 수 없습니다.', error: true})
            }
            else {
              if(dvcd == 'pop'){
                lv_Vm.toastText = '저장되었습니다.'
              }
              lv_Vm.fn_bsActInptClose()
              lv_Vm.fn_btn_S_OnClick()
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_ConfirmSign
    * 설명       : 신인서명 클릭 시 호출
    ******************************************************************************/
    fn_ConfirmSign (pObj) {
      this.lv_actObj = this.$bizUtil.cloneDeep(pObj)
      if (this.auth === '1') {
        this.lv_actObj.signYn = '1'
      } else {
        this.lv_actObj.mngrSignYn = '1'
      }
      this.toastText = '서명되었습니다.'
      this.fn_signAlert(this.$t('ap')['EAPC008'])
    },
    /******************************************************************************
    * Function명 : fn_ConfirmCancelSign
    * 설명       : 신인서명취소 클릭 시 호출
    ******************************************************************************/
    fn_ConfirmCancelSign (pObj, signSc) {
      this.lv_actObj = this.$bizUtil.cloneDeep(pObj)
      if (signSc === 1) {
        this.lv_actObj.signYn = '0'
      } else {
        this.lv_actObj.mngrSignYn = '0'
      }
      this.toastText = '서명이 취소되었습니다.'
      this.fn_signAlert(this.$t('ap')['EAPC009'])
    },
    fn_signAlert(content){
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          //single: true,
          title: "",
          content: content,
          title_pos_btn: "확인",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_AlertPop)
            this.fn_ServiceSaveData()
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_Open511Pop
     * 설명       : FC문자/전화 팝업 호출
     * 
     ******************************************************************************/
    fn_Open511Pop (cnsltNm, cnsltNo) {
      if(this.auth == '3' || this.auth == '4')
      this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
        properties: {
          pCnsltNm: cnsltNm,
          pCnsltNo: cnsltNo,
          FCTelBtn: "전화하기",
          FCSMSBtn: "문자하기"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_popup511)
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_popup511)
          }
        }
      })
    }
    
  }
}
</script>
    